.fooTrMain {
  background-image: url(https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/black-bg.png.webp);
  background-color: #1f2122 !important;
  position: relative;
}

.flvr {
  width: 18%;
  position: absolute;
  bottom: -1px;
  right: -128px;
}

.uson {
  font-family: "Syne", sans-serif !important;
  color: #f2751a;
  font-size: 19px;
  font-weight: 600;
  line-height: 125%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socsinner {
  border: 2px solid #fff;
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.socbg4:hover {
  /* background: #18a1ef; */
  background: #000;
  transition: 1s;
}
.socbg5:hover {
  background: #5865F2;
  transition: 1s;
}
.socbg3:hover {
  background: #1e9ed6;
  transition: 1s;
}
.socbg2:hover {
  transition: 1s !important;
  background: linear-gradient(
    358.63deg,
    #ffb14e -14.99%,
    #f24d59 50.81%,
    #4664c9 111.82%
  );
}

.flwr {
  position: relative;
  right: 250px;
  height: 200px;
  width: 350px;
}

.platfrm {
  width: 10%;
  color: #fff;
}

.ritg {
  margin-top: 3rem;
}

.socs_warp {
  margin-top: 25px;
}

.cmpny {
  color: #fff;
  font-size: 16px;
  font-family: "Syne", sans-serif !important;
}

.fateli {
  font-size: 27px;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .flvr {
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .flvr {
    display: none;
  }
}
