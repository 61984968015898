.itemLogo {
  width: 30px !important;
}

.itemName {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  font-family: "Syne", sans-serif !important;
}

.itemUppre{
    border-bottom: 1px solid #d9d9d9 ;
    padding-bottom: 15px;
}

.meg{
    margin-top: 30px;
}

@media only screen and (max-width:600px) {
  .meg2{
    margin-top: 30px;
}
}

@media (min-width:601px) and (max-width:768px) {
  .meg2{
    margin-top: 30px;
}
}
.Headingh1 {
  font-size: 10px;
}

.team_main {
  font-weight: bold;
}

@media (max-width: 600px) {
  .resp {
    width: 223px;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .img {
    width: 155px !important;
    margin-left: -1px;
    margin-top: -231px;
  }
  .Headingh1 {
    font-size: 22px;
    font-weight: bold;
  }
}
@media (min-width: 300px) and (max-width: 600px) {
  .img2 {
    width: 61%;
    margin-left: 0px;
    margin-top: -232px;
  }
}

.img2 {
  border-radius: 50%;
  position: relative;
}

.umer_img {
  position: relative;
  width: 19%;
}

@media (min-width: 600) and (max-width: 4000) {
  .resp {
    width: 600rem;
  }
}

@media (min-width: 600px) and (max-width: 4000px) {
  .img {
    width: 145px;
    margin-left: rem;
    margin-top: -13.5rem;
  }
  .Headingh1 {
    font-size: 22px;
    font-weight: bold;
  }
}
@media (min-width: 600px) and (max-width: 4000px) {
  .img2 {
    width: 145px;
    margin-left: rem;
    margin-top: -221px;
  }
}

/* ---------------------------------- */

.circle {
  position: absolute;
  overflow: hidden;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}
