#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 340px;
  height: 200px;
  object-fit: cover;
}

.slidimg {
  transition: all 0.5s;
  overflow: hidden;
}

.slidimg:hover {
  transform: scale(1.1);
}
.mainimg {
  overflow: hidden !important;
}

.apparr {
  width: 48px;
  height: 48px;
  padding: 10px;
  border: 1px solid #1f2122 !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.just {
  color: #000;
  background-color: #eee;
  background-image: url(https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/bg.png.webp);
}

.swiper-pagination-bullet {
  display: none !important;
}

.seecase {
  border-radius: 40px;
  text-decoration: none;
  color: white;
  padding: 8px 15px;
  background-color: #000;
  margin: 20px 0px !important;
}

.maindasda:hover .seecase {
  display: block !important;
}

.seecase:hover {
  background-color: #f2751a;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
color: #000 !important;
border: 2px solid #f2751a;
border-radius: 50%;
padding: 10px 14px;
font-size: 17px;
font-weight: 900;
display: none;

}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  color: #000 !important;
border: 2px solid #f2751a;
border-radius: 50%;
padding: 10px 14px;
font-size: 17px;
font-weight: 900;
  display: none;
}

.det{
  text-decoration: none !important;
}
.det2{
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .section-heading h1 {
    font-size: 23px;
    /* line-height: 30%; */
    line-height: 30px;
    font-weight: 700;
    display: inline;
    /* background-color: #f2751a; */
    font-feature-settings: "pnum" on, "lnum" on;
  }
}
.prev{
  left: var(--swiper-navigation-sides-offset, 10px);
    right: auto;
    /* top: var(--swiper-navigation-top-offset, 50%); */
    /* width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size); */
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.prev-disabled{
  opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}

.prev:after{
  color: red !important;
    border: 8px solid rgb(245, 253, 14);
    display: flex;
    position: revert !important;
    content: 'prev';
}

.next{
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
  /* position: absolute; */
    /* top: var(--swiper-navigation-top-offset, 50%);
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size); */
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.next:after{
  content: "next";
}

.boost{
  background: transparent !important;
}