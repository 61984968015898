
.mainbl{
    background-image: url(https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/black-bg.png.webp);
    background-color: #1f2122 !important; 
    color: #fffefd !important;
    position: fixed;
    width: 100%;
    z-index: 2;
    padding: 10px 0px !important;
}

.bl_LogO{
    width: 20%;
}
.lOgoUpper{
    width: 30%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0px;
}
.lOgoUpper2{
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0px;
}

.nvlis{
    color: #fffefd !important;
}
.nvlis:hover{
    opacity: 1;
    color: #f2751a;
}

.navbar-collapse{
    display: flex;
    justify-content: end !important;
}

.lnkUpper{
    margin-right: 16rem;
    gap: 2rem;
}

.arrowspn{
    display: inline-flex;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: #fffefd;
    overflow: hidden;
    position: relative;
    margin-right: 14px;
    transition: .3s;
}

.arrowspn:before {
    content: "";
    width: 11px;
    height: 11px;
    background: url(../assets/right-arrow.svg) no-repeat 50%;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) !important;
    transition: .8s !important;
}

.arrowspn:after{
    content: "";
    width: 11px;
    height: 11px;
    background: url(../assets/right-arrow.svg) no-repeat 50%;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) !important;
    transition: .8s;
}

/* .nvlis:active {
    background-color: transparent !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
} */



.nvlis:hover span:after {
    opacity: 0 !important;
    transform: translate(200%,-50%) !important;
}

.nvlis:hover span:before {
    transform: translate(-50%,-50%) !important;
    opacity: 1 !important;
}

.nvlis span:before {
    opacity: 0 !important;
    transform: translate(-200%,-50%) !important;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: url(../assets/ci_menu-alt-01.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: white;
}

@media only screen and (max-width:600px) {
    .navbar-collapse{
        display: flex;
        flex-direction: column;
        justify-content: center !important;
    }

    .lnkUpper{
        margin-right: 0rem;
        gap: 1rem;
    }

    .bl_LogO {
        width: 40%;
    }

}

@media (min-width:601px) and (max-width:768px) {
    .navbar-collapse{
        display: flex;
        flex-direction: column;
        justify-content: center !important;
    }

    .lnkUpper{
        margin-right: 0rem;
        gap: 1rem;
    }

}

@media (min-width:786px) and (max-width:1025px) {
    .lOgoUpper {
        width: 178px;
    }
}

