.btn-close {
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: white;
  color: black;
}

.bglft {
  background-image: url(https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/black-bg.png.webp);
  background-color: #1f2122 !important;
  border-radius: 19px;
}

.section_titl {
  color: #e7f870;
  font-size: 34px;
  line-height: 125%;
  font-family: "Syne", sans-serif !important;
}

.email {
  color: #fffefd;
  font-size: 18px;
  text-decoration: none;
}

a.line-animation:before {
  content: "";
  width: 0;
  height: 2px;
  background: #fffefd;
  position: absolute;
  top: 100%;
  left: 0;
  transition: 0.4s;
}

a.line-animation {
  position: relative;
  cursor: none;
}

a.line-animation:hover:before {
  width: 100%;
}

.modal-lg,
.modal-xl {
  --bs-modal-width: 1189px !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  font-family: "Syne", sans-serif !important;
  color: white !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #fff !important;
  font-family: "Syne", sans-serif !important;
}

.css-mnn31 {
  color: #fff !important;
}

.css-mqt2s5 {
  color: #fff !important;
}

.css-zrqpq1::before {
  border-bottom: 1px solid white !important;
}

.css-348fk2 {
  color: #fff;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: white !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  border-bottom: 1px solid #fff !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none !important;
}

.social {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.aps {
  border: 1px solid #fff;
  padding: 18px 20px;
  cursor: pointer;
  position: relative;
  font-family: "Syne", sans-serif !important;
}

.whats {
  color: #fff;
  font-size: 23px;
}

.aaps1:hover {
  transition: 1s;
  background: #00e67d;
}

.aaps2:hover {
  transition: 1s;
  background: #1e9ed6;
}

.put {
  display: none;
}

.atchtitle {
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-family: "Syne", sans-serif !important;
}

.lbl {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 4px;
}

.ppcy {
  color: #f2751b;
  text-decoration: none;
}

.form-check-input:checked {
  background-color: #f2751b;
  border-color: #f2751b;
}

.warning {
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-family: "Syne", sans-serif !important;
}

.suub {
  background-color: #fff;
  color: black;
  border-radius: 30px;
}

.suub:hover {
  background-color: #f2751b;
  color: #fff;
}

.copylogo {
  width: 14px;
}

.copylogo:hover {
  opacity: 0.5;
}

.modal-content {
  background-color: transparent !important;
  background-clip: padding-box;
  border: none !important;
}

.modal-header {
  border-bottom: none !important;
}

.tele {
  text-decoration: none;
}

.maillayer {
  display: flex;
  gap: 1rem;
}

.css-348fk2 {
  color: #fff !important;
}

.css-zrqpq1::after {
  border: none !important;
}

@media only screen and (max-width: 600px) {
  .warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .social {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }

  .maillayer {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .aps {
    padding: 18px 13px;
  }
}
