.stackFupper {
  padding: 6rem 0px;
  background-image: url(https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/bg.png.webp);
}

.section_heading {
  font-size: 90px;
  line-height: 90%;
  font-weight: 700;
  font-family: "Syne", sans-serif !important;
}


.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
    color: black !important;
    font-size: 15px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    border-radius: 35px;
    background: black;
    color: #fff !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected:hover {
    background-color: #f2751a;
    color: #fff !important;
}

.css-1q2h7u5.Mui-selected {
  border-radius: 35px !important;
  background-color: #000 !important;
  color: #fff !important;
}
.css-1q2h7u5.Mui-selected:hover {
  background-color: #f2751a !important;
}

.css-ttwr4n {
  display: none !important;
}

.css-1q2h7u5 {
  text-transform: capitalize !important;
  font-size: 15px !important;
  color: black !important;
}

.css-1aquho2-MuiTabs-indicator {
    display: none !important;
}


/* .css-1q2h7u5 {
  color: #000 !important;
  border: 2px solid;
  border-radius: 35px !important;
  background-color: purple !important;
} */







@media only screen and (max-width: 600px) {
  .section_heading {
    font-size: 45px;
  }
}
