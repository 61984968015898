.dasawali{
    position: absolute;
    margin-left: 284px;
    height: 80%;
    bottom: 35px;
    width: 45% !important;
    display: none;
    
}

.onhover:hover .dasawali{
    display: block !important;


}

.ywali{

    position: absolute;
    margin-left: 278px;
    bottom: 28px;
    display: none;

}
.onhover:hover .ywali{
    display: block !important;

}

.xwali{
    position: absolute;
    margin-left: 270px;
    bottom: 30px;
    display: none;
}
.onhover:hover .xwali{
    display: block !important;

}
.wwali{
    position: absolute;
    margin-left: 270px;
    bottom: 10px;
    display: none;
}
.onhover:hover .wwali{
    display: block !important;

}
.vwli{
    position: absolute;
    margin-left: 270px;
    bottom: 10px;
    display: none;
}
.hver_img{

    position: absolute !important;
    margin-left: 279px;
    bottom: 23px;
    display: none;
}

.onhover:hover .vwli{
    display: block !important;

}

.done{

    height: 100% !important;

}
.textcolor{
    color: #f2751a;
}
.section-heading h1{
font-size: 80px;
line-height: 90%;
font-weight: 700;
display: inline;
font-feature-settings: "pnum" on,"lnum" on;
}
.forboder{

    border: 1px solid #d9d9d9;
    padding: 32px;
    position: relative;
    border-radius: 20px;
    display: flex;
    transition: .2s;

}
.heiding{
    font-size: 34px;
    line-height: 125%;
}
.phraheight{
    line-height: 30px;
}

.onhover:hover {
    color: white !important;
    background-color: black !important;
}  


.h{
    content: "";
    width: 100%;
    height: 9px;
    position: absolute;
    left: 0;
    top: 89%;
    background: url(../assets/forstline.png) no-repeat 50%;
    background-size: 100% 100%;
    top: 80%;
}
.yai{
   text-decoration: underline;
  
}

.onhover:hover .hver_img{
    display: block !important;

}


/* ----------- for mobiles     */

@media only screen and (min-width: 320px) and (max-width: 600px)
{


    .onhover:hover .dasawali{
        display: none !important;
    
    
    }
.onmobile{
    display: flex !important;
    flex-direction:column-reverse !important;
}
.picture{
    width: 100% !important;
}
.section-heading h1{    
font-size: 23px;
line-height: 30%;
font-weight: 700;
display: inline;
font-feature-settings: "pnum" on,"lnum" on;
}


.onhover:hover .vwli{
    display: none !important;

}
.onhover:hover .ywali{
    display: none !important;

}
.onhover:hover .xwali{
    display: none !important;

}

.onhover:hover .wwali{
    display: none !important;

}
.onhover:hover .hver_img{
    display: none !important;
}

}


/* ------------------- for tab----     */

@media only screen and (min-width: 601px) and (max-width: 800px)
{

.onmobile{
    display: flex !important;
    flex-direction:column-reverse !important;
}
.picture{
    width: 100% !important;
}
.onhover:hover .dasawali{
    display: none !important;


}
.onhover:hover .vwli{
    display: none !important;

}
.onhover:hover .ywali{
    display: none !important;

}
.onhover:hover .xwali{
    display: none !important;

}

.onhover:hover .wwali{
    display: none !important;

}
.onhover:hover .hver_img{
    display: none !important;
}

 
}



/* @media only screen and (min-width: 801px) and (max-width: 1200px)
{
    
    .onhover:hover .dasawali{
        display: none !important;
    
    
    }
    
    .onhover:hover .ywali{
        display: none !important;
    
    }
    .onhover:hover .xwali{
        display: none !important;
    
    }

    .onhover:hover .wwali{
        display: none !important;
    
    }
    .onhover:hover .hver_img{
        display: none !important;
    }

} */


@media only screen and (min-width: 801px) and (max-width: 1400px)
{
    .vwli{
        position: absolute;
        margin-left: 230px;
        height: 85%;
        bottom: 30px;
        display: none;
    }


    .wwali{
        position: absolute;
        margin-left: 225px;
        height: 83%;
        bottom: 30px;
        display: none;
    }
  
   
    .ywali{

        position: absolute;
        margin-left: 235px;
        height: 85%;
        bottom: 29px;
        display: none;
    
    }
   

    .xwali{
        position: absolute;
        margin-left: 225px;
        bottom: 26px;
        height: 85%;
        display: none;
    }

    
    .hver_img{

        position: absolute !important;
        margin-left: 230px;
        bottom: 29px;
        height: 85%;
        display: none;
    }
    .dasawali{
        position: absolute;
        margin-left: 240px;
        bottom: 25px;
        width: 44% !important;
        height: 85%;
        display: none;
        
    }
}