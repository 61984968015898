.sEeCase {
  font-family: "Syne", sans-serif !important;

  padding: 6rem 0px;
  background-image: url(https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/bg.png.webp);
}

.CaSeeUproW {
  margin-top: 4rem;
}

.titles{
  padding-left: 30px;
}

.caseHeading {
  font-size: 70px;
  line-height: 85%;
  font-weight: 700;
  font-family: "Syne", sans-serif !important;
  text-align: start;
}

.media {
  margin-top: 8rem;
}

.CaSeeUproW2 {
  margin-top: 5rem;
}

.LeFtTitlE {
  text-align: start;
  font-family: "Syne", sans-serif !important;
}

.LeFtTitlE h6 {
  font-size: 25px;
}

.detail2TiTlE {
  font-size: 37px;
  line-height: 100%;
  font-weight: 700;
  text-align: start;
  font-family: "Syne", sans-serif !important;
}

.detAilPeRa {
  font-weight: 400;
  font-size: 21px;
  line-height: 170%;
  text-align: start;
}

.liuPrr {
  text-align: start;
}

.clName {
  font-size: 25px;
  font-weight: 700;
}

.nme {
  font-size: 20px;
}

.uppl {
  padding-left: 1rem !important;
  /* display: flex !important;
  flex-direction: column !important;
  padding-left: 0px !important; */
}

.uppli {
  list-style: square !important;
  margin-bottom: 2px !important;
  font-size: 18px !important;
  color: black !important;
  text-align: start !important;
}

.liveew {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 3rem;
}

.seELivE_butn {
  background-color: black;
  color: #fff;
  border-radius: 35px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 18px;
}

.seELivE_butn:hover {
  background-color: #f2751b;
  color: #fff;
}

.CaSeeUproW3 {
  margin-top: 6rem;
}

@media only screen and (max-width: 600px) {
  .caseHeading {
    font-size: 55px;
  }

  .media {
    margin-top: 5rem;
  }

  .CaSeeUproW {
    margin-top: 2rem;
  }

  .rtSdd {
    margin-top: 2rem;
  }

  .rtSdd2 {
    margin-top: 2rem;
  }

  .detail2TiTlE {
    font-size: 31px;
    line-height: 100%;
  }

  .detAilPeRa {
    font-size: 18px;
    line-height: 162%;
  }

  .servs {
    margin-top: 2rem;
  }

  .CaSeeUproW3 {
    margin-top: 4rem;
  }

  .liveew {
    margin-top: 2rem;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .rtSdd2 {
    margin-top: 2rem;
  }

  .media {
    margin-top: 5rem;
  }

  .rtSdd {
    margin-top: 2rem;
  }
}
