
.portfolioMain{
    background-image: url(https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/bg.png.webp);
}

.portRoW {
    margin-top: 7rem;
    padding-bottom: 5rem;
}

.portsecTit {
  padding-top: 10rem;
  font-size: 90px;
  line-height: 85%;
  font-weight: 700;
  font-family: "Syne", sans-serif !important;
  text-align: left;
}

.lowerporCard{
  margin-top: 3rem;
}

.cardpfTitle{
  font-family: "Syne", sans-serif !important;
  font-weight: 600;
  font-size: 30px;
}

.portfCrdPic{
  width: 100%;
  height: 300px;
  border-radius: 12px;
}

.porfrdss{
  border-radius: 12px;
}

.pfCrdBT{
  display: flex;
  justify-content: space-between;
  align-items: center;
}




@media only screen and (max-width:600px) {
  .portsecTit {
    padding-top: 7rem;
    font-size: 53px;
}

.lowerporCard {
  padding: 0px;
}

.lowerporCard2 {
  padding: 0px;
}

.lee{
  padding-top: 4rem;
}

.pfCrdBT {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2rem;
}

.portfCrdPic {
  height: 220px;
}

}

@media (min-width:601px) and (max-width:768px) {
  .lee{
    padding-top: 4rem;
  }
}

