.fstsc {
  background-image: url(https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/black-bg.png.webp);
  background-color: #1f2122 !important;
}

@import url("https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap");

.blockchain {
  transform: translateX(113px);
  position: relative;
  color: #f2751a;
  white-space: nowrap;
  display: block;
}

.boxblok {
  width: 100%;
}

.blockchain:before {
  content: "";
  width: 102%;
  height: 375%;
  transform: translateY(-50%);
  top: 50%;
  left: -14%;
  position: absolute;
  background: url(../assets/blockchain.png) no-repeat 50%;
  background-size: contain;
}

.blockchain:after {
  content: "";
  background: url(../assets/st.svg) no-repeat 50%;
  background-size: contain;
  width: 48px;
  height: 48px;
  position: relative;
  bottom: 48px;
  left: -5px;
  display: inline-block;
}

.title {
  font-weight: 700;
  font-size: 90px;
  line-height: 85%;
  color: #fff;
  font-family: "Syne", sans-serif;
  display: flex;
  text-align: start;
}

.bdsmain {
  justify-content: space-around;
  padding-top: 8rem;
  padding-bottom: 12rem;
}

.hlpUpper {
  display: flex;
  text-align: start;
  align-items: flex-start;
  justify-content: space-between;
}

.txt {
  font-size: 18px;
  line-height: 170%;
  max-width: 430px;
  padding-top: 45px;
  color: #fff;
}

.circle-btn.lime {
  background: #e7f870 !important;
}

.circle-btn {
  border: none;
  display: flex;
  width: 184px;
  height: 184px;
  border-radius: 50%;
  background: #e7f870 !important;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #1f2122;
  text-decoration: none;
  transition: 0.4s;
  font-weight: 600;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.circle-btn:before {
  content: "";
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  transform: translate(-50%, -50%) scale(0);
  position: absolute;
  transition: 0.5s;
  background: #f2751a;
  border-radius: 50%;
}

.circle-btn:not(:disabled):hover:before {
  transform: translate(-50%, -50%) scale(1) !important;
}

.circle-btn * {
  z-index: 1;
  display: flex;
  align-items: center;
}

.ritimg {
  padding: 0px 0px;
}

.smallSCr{
  display: none;
}

.largScreen{
  display: block;
}

.smalblchN{
  position: relative;
  left: 27px;
}

@media only screen and (max-width: 600px) {
  .smallSCr{
    display: block;
  }

  .largScreen{
    display: none;
  }

  .hlpUpper {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .txt {
    font-size: 18px;
    line-height: 170%;
    padding-top: 0px;
  }

  .circle-btn {
    width: 130px;
    height: 42px;
    border-radius: 35px;
    margin-top: 3rem;
  }

  .ritimg {
    padding: 0px 10px;
    padding-right: 0px !important;
    margin-top: 3rem;
  }

  .boxblok {
    width: 80%;
  }

  .blockchain {
    white-space: unset;
    transform: translateX(27px);
}

  .blockchain:before {
    width: 354px;
    height: 412%;
    transform: translateY(-50%);
    top: 28%;
    left: -15px;
    position: absolute;
    background: url(../assets/blockchain-mob.png) no-repeat 50%;
    background-size: contain;
}

.blockchain:after {
  width: 27px;
  height: 27px;
  position: relative;
  bottom: 20px;
  left: 4px;
  content: "";
    background: url(../assets/st.svg) no-repeat 50%;
    background-size: contain;
}

.title{
      font-size: 55px;
    line-height: 90%;
    display: flex;
    text-align: start;
}

.bdsmain {
  padding: 7rem 0px 4rem 7px;
}

}

@media (min-width:600px) and (max-width:768px) {

.title {
    font-weight: 700;
    font-size: 75px;
}

.hlpUpper {
  display: flex;
  text-align: start;
  align-items: flex-start;
  justify-content: space-around;
}

.heading{
  padding: 0px 10px;
}

.boxblok {
  width: 50%;
}

}

@media (min-width:768px) and (max-width:1024px) {
  .title {
    font-weight: 700;
    font-size: 70px;
}

.circle-btn {
  width: 149px;
  height: 146px;
}

}
